
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import ContextMenuItem from '@/components/context-menu/item.vue'
import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { FlowModule } from '../../store'

@Component({
  components: {
    ContextMenuItem
  },
  name: 'FlowContextMenuCopyText'
})
export default class FlowContextMenuCopyText extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly flowId!: string

  copyAsTextTimer?: number

  copyingAsText = false
  copiedAsText = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get flow () {
    return this.flowModule.flows[this.flowId]
  }

  get disabledMessage () {
    if (this.currentShareLink) {
      return 'You cannot copy code from share links, please request access from an admin'
    }
  }

  async copyAsText () {
    if (!this.flow || this.copyingAsText) {
      return
    }

    try {
      this.copyingAsText = true

      const text = await this.flowModule.flowExportText({
        flowId: this.flow.id,
        landscapeId: this.currentLandscapeId,
        versionId: this.currentVersionId
      })

      await navigator.clipboard.writeText(text)

      this.copiedAsText = true
      clearTimeout(this.copyAsTextTimer)
      this.copyAsTextTimer = window.setTimeout(() => {
        this.copiedAsText = false
      }, 2000)

      this.alertModule.pushAlert({
        message: 'Flow copied as text'
      })

      analytics.flowCopyText.track(this, {
        flowStepAlternatePathCount: Object.values(this.flow.steps).filter(o => o.type === 'alternate-path').length,
        flowStepCount: Object.values(this.flow.steps).length,
        flowStepOutgoingCount: Object.values(this.flow.steps).filter(o => o.type === 'outgoing').length,
        flowStepParallelPathCount: Object.values(this.flow.steps).filter(o => o.type === 'parallel-path').length,
        flowStepReplyCount: Object.values(this.flow.steps).filter(o => o.type === 'reply').length,
        flowStepSelfActionCount: Object.values(this.flow.steps).filter(o => o.type === 'self-action').length,
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
      throw err
    } finally {
      this.copyingAsText = false
    }
  }
}
