
import { FlowPartial, Task } from '@icepanel/platform-api-client'
import pick from 'lodash/pick'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import ContextMenuItem from '@/components/context-menu/item.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { FlowModule } from '../../store'

@Component({
  components: {
    ContextMenuItem
  },
  name: 'FlowContextMenuShowConnectionNames'
})
export default class FlowContextMenuShowConnectionNames extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly flowId!: string

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get landscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get flow () {
    return this.flowModule.flows[this.flowId]
  }

  get showConnectionNames () {
    return this.flow.showConnectionNames
  }

  get disabledMessage () {
    if (this.currentShareLink) {
      return 'You cannot change share links, please request access from an admin'
    } else if (this.currentVersionId !== 'latest') {
      return 'You cannot change a previously created version'
    } else if (!(this.landscapePermission === 'write' || this.landscapePermission === 'admin')) {
      return 'You don\t have edit access, please request access from an admin'
    }
  }

  updateFlow (props: FlowPartial) {
    if (!this.flow) {
      return
    }

    const revertTasks: Task[] = [{
      id: this.flow.id,
      props: pick(this.flow, Object.keys(props)),
      type: 'flow-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { flow, flowUpdate } = this.flowModule.generateFlowCommit(this.flow.id, props)
    this.flowModule.setFlowVersion(flow)
    this.editorModule.addToTaskQueue({
      func: () => this.flowModule.flowUpdate({
        flowId: flow.id,
        landscapeId: this.currentLandscapeId,
        props: flowUpdate,
        versionId: this.currentVersionId
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: flow.id,
        props: flowUpdate,
        type: 'flow-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })
  }
}
