
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import ContextMenuContent from '@/components/context-menu/content.vue'
import ContextMenu from '@/components/context-menu/index.vue'

import CopyCode from './copy-code.vue'
import CopyText from './copy-text.vue'
import DeleteFlow from './delete-flow.vue'
import DuplicateFlow from './duplicate-flow.vue'
import PinFlow from './pin-flow.vue'
import ShowAllSteps from './show-all-steps.vue'
import ShowConnectionNames from './show-connection-names.vue'

@Component({
  components: {
    ContextMenu,
    ContextMenuContent,
    CopyCode,
    CopyText,
    DeleteFlow,
    DuplicateFlow,
    PinFlow,
    ShowAllSteps,
    ShowConnectionNames
  },
  name: 'FlowContextMenu'
})
export default class FlowContextMenu extends Vue {
  @Prop() readonly flowId!: string
  @Prop() readonly menuProps?: any
  @Prop() readonly value?: any

  @Prop({ default: true, type: Boolean }) readonly showConnectionNames!: boolean
  @Prop({ default: true, type: Boolean }) readonly showAllSteps!: boolean

  @Ref() readonly menuRef!: ContextMenu
}
