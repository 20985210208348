
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import ContextMenuItem from '@/components/context-menu/item.vue'
import * as analytics from '@/helpers/analytics'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { FlowModule } from '../../store'

@Component({
  components: {
    ContextMenuItem
  },
  name: 'FlowContextMenuPinFlow'
})
export default class FlowContextMenuPinFlow extends Vue {
  flowModule = getModule(FlowModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly flowId!: string

  get flow () {
    return this.flowModule.flows[this.flowId]
  }

  get pinned () {
    return this.flow.pinned
  }

  get flows () {
    return Object.values(this.flowModule.flows)
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get landscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get disabledMessage () {
    if (this.currentShareLink) {
      return 'You cannot change share links, please request access from an admin'
    } else if (this.currentVersionId !== 'latest') {
      return 'You cannot change a previously created version'
    } else if (!(this.landscapePermission === 'write' || this.landscapePermission === 'admin')) {
      return 'You don\t have edit access, please request access from an admin'
    }
  }

  toggleFlowPin (flowId: string) {
    const { flow, flowUpdate } = this.flowModule.generateFlowCommit(flowId, {
      pinned: !this.flow.pinned,
      pinnedIndex: this.flows.length
    })
    this.flowModule.setFlowVersion(flow)
    this.editorModule.addToTaskQueue({
      func: () => this.flowModule.flowUpdate({
        flowId,
        landscapeId: this.flow.landscapeId,
        props: flowUpdate,
        versionId: this.flow.versionId
      })
    })

    if (this.flow.pinned) {
      analytics.pinItem.track(this, {
        pinType: 'flow'
      })
    } else {
      analytics.unpinItem.track(this, {
        pinType: 'flow'
      })
    }
  }
}
