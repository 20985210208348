
import { FlowRequired, FlowStep, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import ContextMenuItem from '@/components/context-menu/item.vue'
import randomId from '@/helpers/random-id'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { FlowModule } from '../../store'

@Component({
  components: {
    ContextMenuItem,
    OrganizationUpgradeMenu
  },
  name: 'FlowContextMenuDuplicateFlow'
})
export default class FlowContextMenuDuplicateFlow extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModel = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly flowId!: string

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get landscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get flow () {
    return this.flowModule.flows[this.flowId]
  }

  get flows () {
    return Object.values(this.flowModule.flows)
  }

  get diagram () {
    return this.diagramModule.diagrams[this.flow.diagramId]
  }

  get diagramModel () {
    return this.modelModel.objects[this.diagram.modelId]
  }

  get diagramFlows () {
    return this.flows.filter(o => o.diagramId === this.diagram.id)
  }

  get flowsLimitReached () {
    return this.flows.length >= this.currentOrganizationLimits.flows || this.diagramFlows.length >= this.currentOrganizationLimits.flowsPerDiagram
  }

  get disabledMessage () {
    if (this.currentShareLink) {
      return 'You cannot change share links, please request access from an admin'
    } else if (this.currentVersionId !== 'latest') {
      return 'You cannot change a previously created version'
    } else if (!(this.landscapePermission === 'write' || this.landscapePermission === 'admin')) {
      return 'You don\t have edit access, please request access from an admin'
    }
  }

  async duplicateFlow () {
    const diagram = this.diagram
    if (this.flowsLimitReached) {
      return
    }

    const replaceIdMap: Record<string, string> = {}
    const replaceId = (id: string) => {
      if (!replaceIdMap[id]) {
        replaceIdMap[id] = randomId()
      }
      return replaceIdMap[id]
    }

    const steps = Object.entries(this.flow.steps).reduce<Record<string, FlowStep>>((p, [id, s]) => ({
      ...p,
      [replaceId(id)]: {
        ...s,
        id: replaceId(id),
        pathId: s.pathId ? replaceId(s.pathId) : null
      }
    }), {})

    const flowCreate: FlowRequired = {
      diagramId: diagram.id,
      index: this.flow.index + 1,
      name: `${this.flow.name} copy`,
      showConnectionNames: this.flow.showConnectionNames,
      steps
    }
    const { flow, flowUpsert } = this.flowModule.generateFlow(this.currentLandscape.id, this.currentVersion.id, flowCreate)
    this.flowModule.setFlow(flow)
    this.editorModule.addToTaskQueue({
      func: () => this.flowModule.flowUpsert({
        flowId: flow.id,
        landscapeId: this.currentLandscape.id,
        props: flowUpsert,
        versionId: this.currentVersion.id
      })
    })

    const revertTasks: Task[] = [{
      id: flow.id,
      type: 'flow-delete'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    if (this.$route.name?.endsWith('-diagram')) {
      await this.$replaceQuery({
        flow: flow.handleId,
        flow_path: undefined,
        flow_step: undefined
      })

      this.alertModule.pushAlert({
        message: 'Flow duplicated'
      })
    } else {
      const explore = await this.diagramModule.diagramsExplore({
        diagramId: diagram.id,
        landscapeId: this.currentLandscape.id,
        modelId: diagram.modelId,
        versionId: this.currentVersion.id
      })
      if (explore) {
        this.editorModule.addToTaskQueue({
          func: () => this.diagramModule.diagramAction({
            action: 'zoom',
            diagramId: explore.diagram.id,
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          })
        })

        this.$router.push({
          name: this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
          params: {
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          },
          query: {
            comment: undefined,
            connection: undefined,
            diagram: explore.diagram.handleId,
            flow: flow.handleId,
            flow_parent: undefined,
            flow_path: undefined,
            flow_step: undefined,
            model: explore.modelObject.handleId,
            object: undefined,
            x1: undefined,
            x2: undefined,
            y1: undefined,
            y2: undefined
          }
        })
      }
    }

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: flow.id,
        props: flowUpsert,
        type: 'flow-create'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })

    this.$emit('click')
  }
}
